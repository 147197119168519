<!-- <div class="col-sm-12" style="background-color: #ffd533;border-radius: 15px;padding: 15px;margin: 15px 0 20px;">
  <h1>Comunicado:</h1>
  <h4 style="padding: 0 15px;">Devido a uma manutenção no sistema, no dia <strong>13/12/2022</strong> os chamados serão atendidos via WhatsApp,
     qualquer dúvida entre em contato conosco pelo telefone: <strong>(11)99429-8321</strong>, desde já agradecemos a compreensão de todos.
  </h4>
</div> -->
<div class="mat-elevation-z8 tabela">
  <mat-expansion-panel #panel4 expanded hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h1 class="title">
          {{ "Módulos " + site }}
          <mat-icon class="expansion-icone">{{
            panel4.expanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
          }}</mat-icon>
        </h1>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="margin-top">
      <div
        [ngClass]="{
          'row-cols-lg-5': usuarioproibido === true && !possuiSegregacao,
          'row-cols-lg-3': usuarioproibido === false && possuiSegregacao,
          'grid-parent': usuarioproibido && possuiSegregacao,
          'modulo row justify-content-md-center': 1 === 1
        }"
      >
        <div
          *ngIf="!compartilhado"
          [ngClass]="{
            'col-12': !isSustentacao
          }"
        >
          <div class="align-box" (click)="validarAcesso(2)">
            <div class="processos-box background3">
              <p class="clickview">Clique para ver</p>
              <h3 class="h3-title">
                <mat-icon style="font-size: 50px">description</mat-icon><br />{{
                  tituloModuloAuditoria
                }}
              </h3>
              <div class="numberprocess-box">
                <span class="col-sm-1 numberprocess"></span>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="modulosDisponiveis.includes('3')"
          [ngClass]="{
            'col-12': !isSustentacao
          }"
        >
          <!-- Box 1 - Processos em Classificação -->
          <div class="align-box" (click)="validarAcesso(3)">
            <div class="processos-box background4">
              <p class="clickview">Clique para ver</p>
              <h3 class="h3-title">
                <mat-icon style="font-size: 50px">ballot</mat-icon><br />{{
                  tituloModuloRevisao
                }}
              </h3>
              <div class="numberprocess-box">
                <span class="col-sm-1 numberprocess"></span>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="emalote && modulosDisponiveis.includes('1')"
          [ngClass]="{
            'col-12': !isSustentacao
          }"
        >
          <!-- <div class="col-12"> -->

          <div class="align-box" (click)="validarAcesso(1)">
            <div
              class="processos-box {{
                APP === 'impostograma' ? 'background3' : 'background2'
              }}"
            >
              <p class="clickview">Clique para ver</p>
              <h3 class="h3-title">
                <mat-icon style="font-size: 50px">card_travel</mat-icon><br />{{
                  tituloModuloEmalote
                }}
              </h3>
              <div class="numberprocess-box">
                <span class="col-sm-1 numberprocess"></span>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="modulosDisponiveis.includes('5')"
          [ngClass]="{
            'col-12': !isSustentacao
          }"
        >
          <!-- Box 3 - Processos Concluídos -->
          <div class="align-box" (click)="validarAcesso(5)">
            <div class="processos-box background1">
              <p class="clickview">Clique para ver</p>
              <h3 class="h3-title">
                <mat-icon style="font-size: 50px">travel_explore</mat-icon
                ><br />{{ tituloModuloConsulta }}
              </h3>
              <div class="numberprocess-box">
                <span class="col-sm-1 numberprocess"></span>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="usuarioproibido && !compartilhado && possuiSegregacao"
          [ngClass]="{
            'col-12': !isSustentacao
          }"
        >
          <!-- Box 3 - Processos Concluídos -->
          <div class="align-box" (click)="validarAcesso(4)">
            <div class="processos-box background2">
              <p class="clickview">Clique para ver</p>
              <h3 class="h3-title">
                <mat-icon style="font-size: 50px">calculate</mat-icon><br />{{
                  tituloModuloSegregacao
                }}
              </h3>
              <div class="numberprocess-box">
                <span class="col-sm-1 numberprocess"></span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="modulosDisponiveis.includes('6') && possuiSegregacao">
          <div class="align-box" (click)="validarAcesso(6)">
            <div class="processos-box background3">
              <p class="clickview">Clique para ver</p>
              <h3 class="h3-title">
                <mat-icon style="font-size: 50px">refresh</mat-icon>
                <br />Recuperação Simples Nacional
              </h3>
              <div class="numberprocess-box">
                <span class="col-sm-1 numberprocess"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12" *ngIf="modulosDisponiveis.includes('7')">
          <div class="align-box" (click)="validarAcesso(7)">
            <div class="processos-box background4">
              <p class="clickview">Clique para ver</p>
              <h3 class="h3-title">
                <mat-icon style="font-size: 50px">assignment</mat-icon>
                <br />Central de relatórios Turbo SPED
              </h3>
              <div class="numberprocess-box">
                <span class="col-sm-1 numberprocess"></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </mat-expansion-panel>
</div>
