import { ConfigConst } from 'src/app/core/util/config.const';
import { PagamentoService } from '../../../../../shared/services/pagamento.service';
import { ContribuinteService } from '../../../../../shared/services/contribuinte.service';
import { DesabilitarcontribuinteComponent } from '../../../desabilitarcontribuinte/desabilitarcontribuinte.component';
import { ConfirmDialogComponent } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { GestorUsuarioService } from '../../../../../shared/services/gestor-usuario.service';
import { ContratoService } from '../../../../../shared/services/contrato.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Contrato } from '../../../../../shared/model/contrato.model';
import { ContabilidadesComponent } from '../../contabilidades.component';
import { MatDialogRef } from '@angular/material/dialog';
import { GestorUsuario } from '../../../../../shared/model/gestorusuario.model';
import { MessageService } from '../../../../../shared/services/message.service';
import { GestorService } from '../../../../../shared/services/gestor.service';
import { Gestor } from 'src/app/shared/model/gestor.model';
import { Router } from '@angular/router';
import { UsersService } from '../../../../../shared/services/users.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CnpjPromise } from 'cnpj-promise';
import {
  NgxViacepService,
  Endereco,
  ErroCep,
  ErrorValues,
} from '@brunoc/ngx-viacep';
import * as $ from 'jquery';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface DialogData {
  cnpjs: string;
  next?: string;
  title?: string;
  consultaCNPJ?: boolean;
  subtitle?: string;
}

@Component({
  selector: 'app-atualizarcontabilidade',
  templateUrl: './atualizarcontabilidade.component.html',
  styleUrls: ['./atualizarcontabilidade.component.scss'],
})
export class AtualizarcontabilidadeComponent implements OnInit {
  ufList: string[] = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  firstFormGroup: FormGroup;

  gestor: Gestor = {
    CNPJ_Gestor: '',
    Nome: '',
    CEP: '',
    Telefone: '',
    DataHoraContratacaoCorrente: '',
    Status: 'A',
  };

  gestorusuario: GestorUsuario = new GestorUsuario();

  isLinear = true;

  contrato: Contrato = {
    CNPJ_Gestor: '',
    DataHoraContratacao: '',
    QtdeCaixas: 0,
    ValorContrato: '',
  };

  selectplan: string = '';
  qtdcaixas: number = 0;
  precoplan: string = '';

  planoatual;
  continue: boolean = false;
  title: boolean = false;

  titlepagina = 'Alterar ' + ConfigConst.Contabilidade;
  daContabilidade = ConfigConst.daContabilidade;

  private dados: any;

  ocultarIE = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private usersService: UsersService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private GestorService: GestorService,
    public MessageService: MessageService,
    public dialogRef: MatDialogRef<ContabilidadesComponent>,
    public ContratoService: ContratoService,
    public GestorUsuarioService: GestorUsuarioService,
    private viacep: NgxViacepService,
    private serviceContribuinte: ContribuinteService,
    private PagamentoService: PagamentoService,
    private ContribuinteService: ContribuinteService
  ) {}

  ngOnInit(): void {
    if (this.data.title != '') {
      this.titlepagina = this.data.title;
    }

    // alert(this.data.next);
    if (this.data.hasOwnProperty('next') || this.data.next === '1') {
      setTimeout(() => {
        $('#nextstepper').trigger('click');
      }, 2000);
    }

    // this.ContratoService.getByIdContrato(this.data.cnpjs).subscribe(
    //   (res) => {
    //     this.planoatual = res[0]['QtdeCaixas'];
    //     // console.log(res)
    //     this.selecionarPlanos(
    //       res[0]['QtdeCaixas'],
    //       res[0]['QtdeCaixas'],
    //       res[0]['ValorContrato']
    //     );
    //   },
    //   (err) => {
    //     console.error(err);
    //   }
    // );

    this.firstFormGroup = this._formBuilder.group({
      CNPJ_Gestor: ['', Validators.required],
      Razao_Social: ['', Validators.required],
      CEP: ['', Validators.required],
      Telefone: ['', Validators.required],
      Endereco: ['', Validators.required],
      Numero: ['', Validators.required],
      Complemento: [''],
      Cidade: ['', Validators.required],
      UF: ['', Validators.required],
      NomeContato: ['', Validators.required],
      EmailContato: ['', Validators.required],
      IsentoICMS: [''],
      SNAC: [''],
      IM: ['', [Validators.required, Validators.minLength]],
      IE: ['', [Validators.required, Validators.minLength]],
    });

    Object.keys(this.firstFormGroup.controls).forEach((field) => {
      // {1}
      const control = this.firstFormGroup.get(field); // {2}
      control.markAsTouched({ onlySelf: true }); // {3}
    });

    this.buscarGestor();
  }

  buscarGestor() {
    this.GestorService.getByIdGestor(this.data.cnpjs).subscribe((data) => {
      this.dados = data.body;

      this.firstFormGroup.patchValue({
        CNPJ_Gestor: this.dados[0]['CNPJ_Gestor'],
        Razao_Social: this.dados[0]['Nome'],
        CEP: this.dados[0]['CEP'],
        Telefone: this.dados[0]['Telefone'],
        Endereco: this.dados[0]['Endereco'],
        Numero: this.dados[0]['Nro'],
        Complemento: this.dados[0]['Complemento'],
        Cidade: this.dados[0]['Cidade'],
        UF: this.dados[0]['UF'],
        NomeContato: this.dados[0]['ContatoNome'],
        EmailContato: this.dados[0]['ContatoEmail'],
        IsentoICMS:
          this.dados[0]['IsentoICMS'] == 'N' ||
          this.dados[0]['IsentoICMS'] == null
            ? ''
            : true,
        SNAC:
          this.dados[0]['SNAC'] == 'N' || this.dados[0]['SNAC'] == null
            ? ''
            : true,
        IM: this.dados[0]['InscricaoMunicipal'],
        IE: this.dados[0]['IE'],
      });

      this.changeICMS();

      // this.buscacep();

      //Executa a busca cnpj caso o usuário não tenha informado os dados
      if (this.data.consultaCNPJ == true) {
        this.buscarcnpj();
      }
    });
  }

  atualizarGestor(ativo: any) {
    if (this.firstFormGroup.valid) {
      this.gestor.Cidade = String(this.gestor.Cidade)
        .normalize('NFKC')
        .toUpperCase();
      this.gestor.Complemento = String(this.gestor.Complemento)
        .normalize('NFKC')
        .toUpperCase();
      this.gestor.Endereco = String(this.gestor.Endereco)
        .normalize('NFKC')
        .toUpperCase();
      this.gestor.Nome = String(this.gestor.Nome)
        .normalize('NFKC')
        .toUpperCase();

      var today = new Date();
      var date =
        today.getFullYear() +
        '-' +
        ('0' + (today.getMonth() + 1)).slice(-2) +
        '-' +
        today.getDate();
      var time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var dateTime = date + ' ' + time;

      this.gestor.DataHoraContratacaoCorrente = dateTime;
      this.gestor.CNPJ_ANTIGO = this.data.cnpjs;

      this.gestor.IsentoICMS = this.gestor.IsentoICMS == '' ? 'N' : 'S';
      this.gestor.SNAC = this.gestor.SNAC == '' ? 'N' : 'S';

      this.usersService
        .getUsuario(btoa(localStorage.getItem('email_login')))
        .subscribe((data) => {
          var user = {
            // id:localStorage.getItem('id_super'),
            id: 108,
            razao: this.gestor.Nome,
            name: data['0']['Nome'],
            cep: this.gestor.CEP,
            endereco: this.gestor.Endereco,
            number: this.gestor.Nro,
            bairro: '',
            complemento: this.gestor.Complemento,
            cidade: this.gestor.Cidade,
            uf: this.gestor.UF,
            ddd: this.gestor.Telefone.substring(0, 2),
            telefone: this.gestor.Telefone,
            celular: data['0']['CelularSMS'],
            im: this.gestor.IM,
            ie: this.gestor.IE,
            icms:
              this.gestor.IsentoICMS == 'N' || this.gestor.IsentoICMS == null
                ? 0
                : 1,
            snac: this.gestor.SNAC == 'N' || this.gestor.SNAC == null ? 0 : 1,
          };

          this.PagamentoService.getClienteEmail(
            localStorage.getItem('email_login')
          ).subscribe(
            (dados) => {
              user.id = parseInt(dados[0]['id_sacado_sac']);
              // console.log(user);
              // console.log(this.PagamentoService.EditarCliente(user));
              this.PagamentoService.EditarCliente(user).subscribe(
                (res) => {
                  console.log(res);
                },
                (err) => {
                  console.error(err);
                }
              );
            },
            (error) => {
              console.error(error);
            }
          );
        });

      this.GestorService.atualizarGestor(this.gestor).subscribe(
        (data) => {
          this.MessageService.showMessage(
            ConfigConst.ContabilidadeAlteradaComSucesso,
            'success-snackbar'
          );
          if (ativo != '') {
            this.atualizarGestorUsuario();
          } else {
            this.dialogRef.close(false);
          }
        },
        (err) => {
          this.usersService.showMessage(
            'Ops! algo deu errado',
            'error-snackbar'
          );
          console.error(err);
          this.dialogRef.close(false);
        }
      );
    }
  }

  atualizarGestorUsuario(): void {
    // CADASTRAR GESTOR USUARIO
    this.gestorusuario.CNPJ_Gestor = this.gestor.CNPJ_Gestor;
    this.gestorusuario.EmailLogin = localStorage.getItem('email_login');
    this.gestorusuario.CNPJ_ANTIGO = this.data.cnpjs;

    this.GestorUsuarioService.updateGestorUsuario(this.gestorusuario).subscribe(
      (data) => {
        // console.log("Alterado GestorUsuario");
        // console.log(data);
        this.atualizarPlano();
      },
      (err) => {
        // console.log("Erro cadastro GestorUsuario");
        console.error(err);
        this.dialogRef.close(false);
      }
    );
  }

  selecionarPlanos(plano: number, caixas?: number, price?: string) {
    // if(caixas < this.planoatual){
    //   const dialogConfirm = this.dialog.open(ConfirmDialogComponent, {
    //     width: '40vw',
    //     maxWidth:'800px',
    //     maxHeight: '90vh',
    //     data: {
    //       title: "Aviso!",
    //       message: "O plano selecionado é menor que o atual, deseja prosseguir?"
    //     }
    //   });
    //   dialogConfirm.afterClosed().subscribe(dialogResult => {
    //     // console.log(dialogResult);
    //     if(dialogResult){
    //       this.serviceContribuinte.getGestorContribuinte().subscribe(
    //         dados=>{
    //           var contri = [];
    //           for (var i = 0, length = dados.length; i < length; i++) {
    //             if(dados[i]['Status']=="A"||dados[i]['Status']=="P"){
    //               contri.push(dados[i]);
    //             }
    //           }
    //           if(contri.length>caixas){
    //             const dialogDesabilite = this.dialog.open(DesabilitarcontribuinteComponent, {
    //               width: '90vw',
    //               maxWidth:'800px',
    //               maxHeight: '90vh',
    //               data: {
    //                 caixas: caixas,
    //                 gestor: contri
    //               }
    //             });
    //             dialogDesabilite.afterClosed().subscribe(dialogResult => {
    //               // console.log(dialogResult);
    //               if(dialogResult){
    //                 this.continue = true;
    //               }else{
    //                 this.continue = false;
    //               }
    //               if(this.continue){
    //                 this.selectplan = ''+plano;
    //                 this.qtdcaixas = caixas;
    //                 this.precoplan = price;
    //                 var planos = ["plan2", "plan10", "plan20", "plan30", "plan40", "plan50", "plan100"];
    //                 planos.forEach(myFunction);
    //                 function myFunction(item, index) {
    //                   document.getElementById(item).removeAttribute("style");
    //                 }
    //                 document.getElementById('plan'+plano).style.backgroundColor = "#344290";
    //                 document.getElementById('plan'+plano).style.color = "#fff";
    //               }
    //             });
    //           }else{
    //               this.selectplan = ''+plano;
    //               this.qtdcaixas = caixas;
    //               this.precoplan = price;
    //               var planos = ["plan2", "plan10", "plan20", "plan30", "plan40", "plan50", "plan100"];
    //               planos.forEach(myFunction);
    //               function myFunction(item, index) {
    //                 document.getElementById(item).removeAttribute("style");
    //               }
    //               document.getElementById('plan'+plano).style.backgroundColor = "#344290";
    //               document.getElementById('plan'+plano).style.color = "#fff";
    //           }
    //         }
    //       );
    //     }
    //   });
    // }else{
    //   this.continue = true;
    //   this.selectplan = ''+plano;
    //   this.qtdcaixas = caixas;
    //   this.precoplan = price;
    //   var planos = ["plan2", "plan10", "plan20", "plan30", "plan40", "plan50", "plan100"];
    //   planos.forEach(myFunction);
    //   function myFunction(item, index) {
    //     document.getElementById(item).removeAttribute("style");
    //   }
    //   document.getElementById('plan'+plano).style.backgroundColor = "#344290";
    //   document.getElementById('plan'+plano).style.color = "#fff";
    // }
  }

  atualizarPlano() {
    if (this.selectplan == '') {
      this.MessageService.showMessage(
        'Por favor selecione um Plano',
        'warning-snackbar'
      );
    } else {
      if (this.continue) {
        //console.log("cadastrar plano = "+this.selectplan+" caixas"+this.qtdcaixas+" valor"+this.precoplan);
        if (this.planoatual == this.qtdcaixas) {
          // alert("O Plano não foi alterado");
          this.dialogRef.close(true);
        } else {
          this.contrato.CNPJ_Gestor = this.gestor.CNPJ_Gestor;
          this.contrato.DataHoraContratacao =
            this.gestor.DataHoraContratacaoCorrente;
          this.contrato.QtdeCaixas = this.qtdcaixas;
          this.contrato.ValorContrato = this.precoplan;
          this.contrato.CNPJ_ANTIGO = this.data.cnpjs;

          // console.log(this.contrato);
          //console.log(data);

          var plano = {};
          plano['2'] = 3;
          plano['10'] = 4;
          plano['20'] = 5;
          plano['30'] = 6;
          plano['40'] = 7;
          plano['50'] = 8;
          plano['100'] = 9;

          // MIGRAR PLANO NO SUPERLOGICA
          this.PagamentoService.MigrarPlano(
            parseInt(localStorage.getItem('id_contrato')),
            plano[this.qtdcaixas],
            12,
            this.gestor.CNPJ_Gestor
          ).subscribe(
            (res) => {
              //retorno da migração
              // console.log(res);

              this.MessageService.showMessage(
                'Você está efetuando a troca do seu plano - As próximas mensalidades serão R$ ' +
                  this.precoplan,
                'warning-snackbar'
              );

              this.ContratoService.updateContrato(this.contrato).subscribe(
                (data) => {
                  this.MessageService.showMessage(
                    data['data']['message'],
                    data['data']['classe']
                  );
                  //ATUALIZAR CONTRIBUINTES NÃO CONTRATADOS CASO EXISTA.
                  this.atualizarContribuinteN();
                },
                (err) => {
                  this.MessageService.showMessage(
                    'Ops! algo deu errado',
                    'error-snackbar'
                  );
                  console.error(err);
                  this.dialogRef.close(false);
                }
              );
            },
            (err) => {
              console.error(err);
              this.dialogRef.close(false);
            }
          );
        }
      }
    }
  }

  buscarcnpj() {
    var CnpjPromise = require('cnpj-promise').default;

    CnpjPromise(this.gestor.CNPJ_Gestor)
      .then((data) => {
        // console.log(data);
        this.firstFormGroup.patchValue({
          Razao_Social: data['nome'].substr(0, 50),
          CEP: data['cep']
            .replace('.', '')
            .replace('-', '')
            .normalize('NFKC')
            .trim(),
          Telefone: data['telefone']
            .substr(0, 14)
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .normalize('NFKC')
            .trim(),
          Endereco: data['logradouro'].substr(0, 50),
          Numero: data['numero'].substr(0, 10),
          Complemento: data['complemento'].substr(0, 50),
          Cidade: data['municipio'].substr(0, 50),
          UF: data['uf'],
          NomeContato: '',
          EmailContato: data['email'],
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  buscacep() {
    this.viacep
      .buscarPorCep(this.gestor.CEP)
      .then((data: Endereco) => {
        this.firstFormGroup.controls['Endereco'].setValue(
          data['logradouro'].substr(0, 50).normalize('NFKC').toUpperCase()
        );
        // this.firstFormGroup.controls['Numero'].setValue("");
        // this.firstFormGroup.controls['Complemento'].setValue(data['complemento'].toUpperCase());
        this.firstFormGroup.controls['Cidade'].setValue(
          data['localidade'].substr(0, 50).normalize('NFKC').toUpperCase()
        );
        this.firstFormGroup.controls['UF'].setValue(data['uf']);

        // console.log(data);
      })
      .catch((error: ErroCep) => {
        // Alguma coisa deu errado :/
        console.error(error.message);
      });
  }

  atualizarContribuinteN() {
    //ATUALIZAR CONTRIBUINTES NÂO CONTRATADOS
    this.serviceContribuinte.getGestorContribuinte().subscribe(
      (dados) => {
        dados = dados['data'];
        let total = 0;
        let ativo = 0;
        let acontratar = 0;
        for (var j = 0, length = dados.length; j < length; j++) {
          let cnpj = dados[j]['CNPJ_Contribuinte'];
          if (
            dados[j]['Status'] == 'A' ||
            dados[j]['Status'] == 'P' ||
            dados[j]['Status'] == 'N'
          ) {
            ativo++;
          }
          if (dados[j]['Status'] == 'N') {
            acontratar++;
          }
          total++;
        }
        let qtdUp = this.qtdcaixas - ativo;
        // console.log('QTDUP='+qtdUp);
        // console.log('qtdCaixas = '+this.qtdcaixas);
        // console.log('Total = '+total);
        // console.log('Ativos = '+ativo);
        // console.log(' A contratar = '+acontratar);

        if (qtdUp > 0) {
          for (var j = 0, length = dados.length; j < length; j++) {
            if (qtdUp >= 1) {
              if (dados[j]['Status'] == 'N') {
                //atualizar contribuinte de N para A OU P
                // console.log(dados[j]);
                let status = 'N';
                if (dados[j]['IndAtiv'] != '' && dados[j]['SNAC'] != '') {
                  status = 'A';
                } else {
                  status = 'P';
                }

                let contribuinte: any = {
                  CNPJ_Contribuinte: dados[j]['CNPJ_Contribuinte'],
                  CNPJ_Gestor: localStorage.getItem('cnpj_gestor'),
                  Status: 'N',
                };

                contribuinte.Status = status;

                // console.log(contribuinte);

                this.ContribuinteService.desabilityContribuinte(
                  contribuinte
                ).subscribe(
                  (data) => {
                    qtdUp--;
                  },
                  (err) => {
                    console.error(err);
                  }
                );
              }
            }
            if (j >= length - 1) {
              setTimeout(() => {
                this.dialogRef.close(true);
              }, 2000);
            }
          }
        } else {
          this.dialogRef.close(true);
        }

        // console.log('Ficou '+qtdUp+' contribuintes disponíveis ')
      },
      (err) => {
        console.error(err);
      }
    );
  }

  SalvarContabilidade() {
    this.atualizarGestor('');
  }

  changeICMS() {
    if (
      this.gestor.IsentoICMS == '' ||
      this.gestor.IsentoICMS == 'N' ||
      this.gestor.IsentoICMS == null
    ) {
      this.ocultarIE = true;
      this.firstFormGroup.controls['IE'].setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(14),
      ]);
      this.firstFormGroup.controls['IE'].updateValueAndValidity();
    } else {
      this.ocultarIE = false;
      this.gestor.IE = '';
      this.firstFormGroup.controls['IE'].clearValidators();
      this.firstFormGroup.controls['IE'].updateValueAndValidity();
    }
  }
}
