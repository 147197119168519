import { OverrideService } from './override.service';
import { UsersService } from './users.service';
import { PagamentoService } from './pagamento.service';
import { Usuario } from './../../view/components/login/usuario';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigConst } from 'src/app/core/util/config.const';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserUtil } from 'src/app/core/util/user.util';
import { GestorUsuarioService } from './gestor-usuario.service';
import { GoogleAnalyticsService } from './googleanalytics.service';
import { MessageService } from './message.service';
import { EventService } from './event.service';
import { GestorstorageService } from './gestorstorage.service';
import { Users } from '../model/users.model';
import { UsuariocompartilhadoService } from './usuariocompartilhado.service';
import { ContribuinteService } from './contribuinte.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  resource = 'api/login';
  baseUri = `${ConfigConst.AWSImpostograma}/${ConfigConst.APP}/${this.resource}`;
  authorization = ConfigConst.AWSAuth;
  user: Users = new Users();
  cnpj;
  users: Users = new Users();
  userUtil = new UserUtil();
  usuarioAutenticado: boolean;
  gestores: Array<string> = [];
  localEvent = new BehaviorSubject<any>(false);
  mostrarMenu = new BehaviorSubject<boolean>(false);

  private token: string;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private messageService: MessageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public eventComponent: EventService,
    private gestorstorageService: GestorstorageService,
    private GestorUsuarioService: GestorUsuarioService,
    private PagamentoService: PagamentoService,
    private usersService: UsersService,
    private OverrideService: OverrideService,
    private UsuarioCompartilhadoService: UsuariocompartilhadoService,
    private ContribuinteService: ContribuinteService
  ) {}

  resetStorage() {
    localStorage.clear()
  }

  auth(usuario: Usuario): Observable<any> {
    return this.httpClient.post<any>(
      this.baseUri,
      { ...usuario, app: ConfigConst.APPSite },
      {
        headers: {
          Authorization: this.authorization,
        },
      }
    );
  }

  resetSenha(usuario: Usuario) {
    this.httpClient
      .post<Usuario>(`${ConfigConst.baseURL}reset-senha`, usuario)
      .subscribe(
        (res) => {
          // console.log(res);
          this.messageService.showMessage(
            'Senha alterada com sucesso',
            'success-snackbar'
          );
          this.router.navigate(['/']);
        },
        (err) => {
          this.messageService.showMessage(
            'Não foi possivel redefinir sua senha no momento',
            'warning-snackbar'
          );
          console.error('reset senha', err);
        }
      );
  }

  fazerLogin(usuario: Usuario, subject: Subject<boolean> = null) {
    if (subject) subject.next(true);

    localStorage.clear();
    this.resetStorage();

    $.getJSON('https://json.geoiplookup.io/?callback=?', function (data) {
      localStorage.setItem('user_ip', data['ip']);
    });

    this.gestorstorageService.setGestorControl('false');

    this.auth(usuario).subscribe({
      next: (auth) => {
        this.resetStorage();
        const user = atob(auth.body.token).split('=');

        this.user = {
          token: user[1],
          emaillogin: user[1],
          perfil: user[2],
          modulosCompartilhados: user[3],
          poc: user[3] == '1',
        };

        if (this.user.perfil == '8') {
          this.UsuarioCompartilhadoService.buscarContribuinteUsuario(
            btoa(this.user.emaillogin)
          ).subscribe(
            (res) => {
              localStorage.setItem('usuario_compartilhado', '1');

              if (
                this.user.modulosCompartilhados &&
                ConfigConst.APP.toLowerCase() === 'impostograma'
              ) {
                localStorage.setItem(
                  'modulos_compartilhado',
                  this.user.modulosCompartilhados
                );
              }
              this.ContribuinteService.getContribuinteGestor(
                res[0]['CNPJ_Contribuinte']
              ).subscribe(
                (gestores) => {
                  // console.log("Gestores ==> ",gestores);

                  if (gestores.length > 0) {
                    var cnpj = '';
                    for (let i = 0, len = gestores.length; i < len; i++) {
                      if (gestores[i]['Status'] === 'A') {
                        cnpj = gestores[i]['CNPJ_Gestor'];
                        break;
                      }
                    }

                    this.googleAnalyticsService.sendEmitter({
                      eventAction: 'Login',
                      eventLabel: '',
                      eventCategory: 'Acesso',
                    });

                    localStorage.setItem('email_login', this.user.emaillogin);
                    localStorage.setItem('user_perfil', this.user.perfil);
                    localStorage.setItem('access_token', auth.body.token);
                    localStorage.setItem('user_poc', this.user.poc ? '1' : '0');
                    //localStorage.setItem('gestor_control', 'false');

                    this.OverrideService.cadastrarLog(
                      'IGUsuario',
                      'Realizou Login'
                    ).subscribe();

                    if (subject) subject.next(false);

                    if (this.gestorstorageService.setGestoresCNPJ(cnpj))
                      this.setEvent(true);

                    this.isValidToken();
                  } else {
                    this.messageService.showMessage(
                      'Esse usuário não possui ' +
                        ConfigConst.umaContabilidade +
                        ', Por favor entre em contato com o Suporte para mais detalhes..',
                      'warning-snackbar'
                    );
                    setTimeout(() => {
                      window.location.href = '/login';
                    }, 5000);
                  }
                },
                (err) => {
                  console.error(err);
                }
              );
            },
            (err) => {
              console.error(err);
            }
          );
        } else {
          this.GestorUsuarioService.getGestorByEmail(
            btoa(this.user.emaillogin)
          ).subscribe((gestores) => {
            localStorage.setItem('usuario_compartilhado', '0');
            if (gestores.body.length > 0) {
              // this.gestorstorageService.setGestores(this.user[1]);

              localStorage.setItem('email_login', this.user.emaillogin);
              localStorage.setItem('user_perfil', this.user.perfil);
              localStorage.setItem('access_token', auth.body.token);
              localStorage.setItem('user_poc', this.user.poc ? '1' : '0');
              //localStorage.setItem('gestor_control', 'false');

              this.googleAnalyticsService.sendEmitter({
                eventAction: 'Login',
                eventLabel: '',
                eventCategory: 'Acesso',
              });

              this.OverrideService.cadastrarLog(
                'IGUsuario',
                'Realizou Login'
              ).subscribe();

              this.setCnpjGestor();
              this.isValidToken();

              if (subject) subject.next(false);
            } else {
              this.messageService.showMessage(
                'Esse usuário não possui ' +
                  ConfigConst.umaContabilidade +
                  ', Por favor entre em contato com o Suporte para mais detalhes..',
                'warning-snackbar'
              );
              setTimeout(() => {
                window.location.href = '/login';
              }, 5000);
            }
          });
        }
        //console.log(localStorage);
      },
      error: (err) => {
        if (subject) subject.next(null);
        this.messageService.showMessage(
          err.error.body.message,
          'warning-snackbar'
        );
        console.error(err);
      },
    });
  }

  usuarioEstaAutenticado() {
    return localStorage.getItem('isLogged') === 'true' ? true : false;
  }

  logout(reloadPage: boolean = false) {
    localStorage.clear();
    this.resetStorage();
    localStorage.setItem('isLogged', 'false');
    
    window.location.href = '/'

    if (reloadPage) {
      window.location.href = '/login';
    }
  }

  isValidToken() {
    this.token = this.getToken();
    if (this.token) {
      localStorage.setItem('isLogged', 'true');
    }
    this.mostrarMenu.next(this.usuarioEstaAutenticado());
    // this.router.navigate(['/dashboard'])
  }

  getToken() {
    return this.userUtil.getToken();
  }

  getEmailLogin() {
    return this.userUtil.getEmailLogin();
  }

  getPerfil() {
    return this.userUtil.getPerfil();
  }

  public setCnpjGestor() {
    if (this.gestorstorageService.setGestores(this.getEmailLogin()))
      this.setEvent(true);
  }

  setEvent(event: any) {
    this.localEvent.next(event);
  }

  contagem = 0;
  recuperaConta(data: any, subjectSubmit: Subject<boolean> = null) {
    if (subjectSubmit) subjectSubmit.next(true);

    this.httpClient
      .post<Usuario>(`${ConfigConst.baseURL}recuperar-conta`, data)
      .subscribe(
        (result: any) => {
          if (subjectSubmit) subjectSubmit.next(false);
          // console.log(result)
          const { status, token } = result;
          if (status === 'OK') {
            this.resetStorage();
            const user = atob(token).split('=');

            this.user = {
              token: user[1],
              emaillogin: user[1],
              perfil: user[2],
              poc: user[3] == '1',
            };
            // console.log(this.user);

            this.users.emaillogin = this.user.emaillogin;

            this.GestorUsuarioService.getGestorByEmail(
              btoa(this.user.emaillogin)
            ).subscribe((res) => {
              this.cnpj = res.body[0].CNPJ_Gestor;
            });

            if (ConfigConst.APP !== 'impostograma') {
              this.users.status = 'A';
              return this.atualizaUsuario(token);
            }

            this.PagamentoService.getClienteEmail(
              this.user.emaillogin
            ).subscribe((res) => {
              this.PagamentoService.getContrato(
                res[0]['id_sacado_sac']
              ).subscribe((result) => {
                if (result[0]['data'][0]['fl_trial_plc'] === '1') {
                  this.users.status = 'T';
                } else {
                  this.users.status = 'A';
                }

                this.atualizaUsuario(token);
              });
            });
          } else if (this.contagem <= 2) {
            /*this.fazerLogin({
              email: data.email,
              senha
            });*/
            this.messageService.showMessage(
              'Essas informações que você enviou não correspondem a uma conta válida',
              'warning-snackbar'
            );
          } else {
            this.messageService.showMessage(
              `Essas informações que você enviou não correspondem a uma conta válida, se não conseguiu ativar sua conta, envie uma mensagem para ${ConfigConst.emailSuporte} com seu "Nome" "E-mail" e "Numero de Telefone" que entraremos em contato.`,
              'warning-snackbar'
            );
          }
          this.contagem++;
        },
        (err) => {
          subjectSubmit.next(false);
          console.error(err);
          this.messageService.showMessage(
            'Não foi possivel recuperar sua conta no momento tente novamente mais tarde!',
            'error-snackbar'
          );
        }
      );
  }

  atualizaUsuario(token: string) {
    this.usersService.updateUsuario(this.users).subscribe(
      (data) => {
        this.usersService.showMessage(
          data['data']['message'],
          data['data']['classe']
        );
        localStorage.setItem('email_login', this.user.emaillogin);
        localStorage.setItem('user_perfil', this.user.perfil);
        localStorage.setItem('user_poc', this.user.poc ? '1' : '0');
        localStorage.setItem('access_token', token);
        //localStorage.setItem('gestor_control', 'false');

        this.setCnpjGestor();
        this.isValidToken();
      },
      (err) => {
        this.usersService.showMessage('Ops! algo deu errado', 'error-snackbar');
        this.router.navigate(['/login']);
        console.error(err);
      }
    );
  }

  checkIsLogged(): boolean {
    const currentUrl = window?.location?.pathname;

    console.log(`currentUrl: ${currentUrl}`);
    
    if (this.usuarioEstaAutenticado()) {
      if (currentUrl === '/login' || currentUrl === '/') {
        window.location.href = '/dashboard'
      }
      return true;
    }

    
    if (currentUrl === '/login') {
      return true;
    }

    localStorage.clear();

    window.location.href = '/login'
    return false;
  }
}
