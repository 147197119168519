import {
  PayloadRecalculoVisaoSituacional,
  PayloadRefreshEmalote,
} from './../model/emalote.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigConst } from '../../core/util/config.const';
import { AuthService } from './auth.service';

import * as _moment from 'moment';

const moment = _moment;

@Injectable({
  providedIn: 'root',
})
export class EmaloteService {
  resource = 'gerar';
  APP = ConfigConst.APP;
  uri = ConfigConst.baseURL + this.resource;
  baseUrl = `${ConfigConst.AWSImpostograma}/${this.APP}/uploads`;
  baseUri = `${ConfigConst.AWSImpostograma}/${this.APP}`;
  awsAuth = ConfigConst.AWSAuth;
  url = `${ConfigConst.Emalote}`;
  api = `${ConfigConst.API}`;
  token = 'TESTE';
  authorization =
    'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy';

  constructor(private httpClient: HttpClient, private auth: AuthService) {}

  convert(xml?: any): Observable<any> {
    // console.log("AQUI =========================>",xml);
    var form = new FormData();
    form.append('xml', '123');

    let data = JSON.stringify({
      XML: xml,
    });

    return this.httpClient.post(this.uri + '/danfe', data);
  }

  downloadFile(url): any {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  ListarChaves(cnpj): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: 'RRwPrJsGdiwdWZ1CZj9srRtCdQ99LPeg',
    });

    return this.httpClient.get<any>(
      ConfigConst.AWS +
        'uploads-' +
        ConfigConst.Ambiente +
        '/uploadKeysNFeStatus?CNPJ_Contribuinte=' +
        cnpj +
        '&CNPJ_Gestor=' +
        localStorage.getItem('cnpj_gestor'),
      { headers }
    );
  }

  ListaDocumentos(
    tipo: string,
    pagination: object,
    payload: any
  ): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Documento/${tipo}`,
      {
        ...pagination,
        cnpj: payload.CNPJ === null ? '' : payload.CNPJ,
        razao_social: '', // (payload.Razao_Social===null)?"":payload.Razao_Social,
        data_emissao_de: moment(payload.start).format('YYYY-MM-DD'),
        data_emissao_ate: moment(payload.end).format('YYYY-MM-DD'),
        uf: payload.UF === null ? '' : payload.UF,
        status: payload.Status === null ? '' : payload.Status,
        chave_acesso:
          payload.Chave_Especifica === null ? '' : payload.Chave_Especifica,
        cnpj_tomador: payload.CNPJ_Tomador === null ? '' : payload.CNPJ_Tomador,
        produto_codigo:
          payload.Codigo_Produto === null ? '' : payload.Codigo_Produto,
        produto_descricao:
          payload.Desc_Produto === null ? '' : payload.Desc_Produto,
        produto_ean: payload.EAN_Produto === null ? '' : payload.EAN_Produto,
        produto_lista_cfop: payload.CFOPS === null ? [] : payload.CFOPS,
        manifestacao: payload.Manifestacao === null ? '' : payload.Manifestacao,
        entrada_saida:
          payload.tipo == 'entrada' ? '0' : payload.tipo == 'saída' ? '1' : '',
        modelo_documento:
          payload.tipo == 'inutilizados' ? payload.arquivo.toUpperCase() : '',
        CNPJ_Contribuinte:
          payload.CNPJ_Contribuinte === null ? '' : payload.CNPJ_Contribuinte,
        flagDivergencia: payload.flagDivergencia === null ? '' : payload.flagDivergencia,
        // "nome_contribuinte": (payload.CNPJ_Contribuinte===null)?"":(payload.CNPJ_Contribuinte!="")?this.contri[payload.CNPJ_Contribuinte]["Nome"]:"",
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  ExportaDocumento(
    payload: any,
    tipoDocumento: string,
    tipoExportacao: 'xlsx' | 'xml' | 'danfe',
    Descricao: string = '',
    ListaChaves: any[] = []
  ): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: this.awsAuth,
      email_login: localStorage.getItem('email_login'),
      CNPJ_Gestor: localStorage.getItem('cnpj_gestor'),
      db_name: localStorage.getItem('database') ?? '',
    });

    return this.httpClient.post<any>(
      `${this.baseUrl}/exportaNotas`,
      {
        CNPJ_Contribuinte:
          payload.CNPJ_Contribuinte === null ? '' : payload.CNPJ_Contribuinte,
        tipoExportacao: tipoExportacao,
        Descricao,
        DataInicio: moment(payload.start).format('YYYY-MM-DD'),
        DataFim: moment(payload.end).format('YYYY-MM-DD'),
        EntradaSaida:
          payload.tipo == 'entrada' ? '0' : payload.tipo == 'saída' ? '1' : '',
        ChaveAcesso:
          payload.Chave_Especifica === null ? '' : payload.Chave_Especifica,
        tipoDocumento,
        UF: payload.UF === null ? '' : payload.UF,
        Status: payload.Status === null ? '' : payload.Status,
        Manifestacao: payload.Manifestacao === null ? '' : payload.Manifestacao,
        ListaChaves,
        cnpj: payload.CNPJ === null ? '' : payload.CNPJ,
        produto_lista_cfop:
          payload.produto_lista_cfop === null ? '' : payload.produto_lista_cfop,
        modelo_documento:
          payload.modelo_documento === null ? '' : payload.modelo_documento,
        produto_codigo:
          payload.produto_codigo === null ? '' : payload.produto_codigo,
        produto_descricao:
          payload.produto_descricao === null ? '' : payload.produto_descricao,
        produto_ean: payload.produto_ean === null ? '' : payload.produto_ean,
        razao_social: payload.razao_social === null ? '' : payload.razao_social,
      },
      { headers }
    );
  }

  ListaSolicitacao(payload: any): Observable<any> {
    let usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Documento/ListaSolicitacao`,
      {
        data_emissao_de: payload.start,
        data_emissao_ate: payload.end,
        diasDataSolicitacao: payload.dataSolicitacao,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  RecalcularDash(payload: PayloadRefreshEmalote): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Dashboard/Refresh`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  ListaCertificado(
    token: string,
    id: string,
    CNPJ_Contribuinte?: string
  ): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }
    return this.httpClient.post<any>(
      `${this.url}/api/Documento/ListaCertificado/${id}${
        CNPJ_Contribuinte ? `/${CNPJ_Contribuinte}` : ''
      }`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  UpdateNotificacaoLida(token: string, id: string): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Documento/UpdateNotificacaoLida/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  UpdateNotificacaoLidaTodas(): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Documento/UpdateNotificacaoLidaTodas`,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  ListaNotificacao(token: string): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Documento/ListaNotificacao`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  CarregarGraficos(
    dash: string,
    payload: any,
    usercompartilhado: string
  ): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Dashboard/Doc/${dash}`,
      payload
        ? {
            cnpj_contribuinte: payload.cnpj_contribuinte,
            competencia: moment(payload.competencia).format('MMYYYY'),
            diasDataSolicitacao: payload.diasDataSolicitacao,
          }
        : {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  RecalcularVisao(
    payload: PayloadRecalculoVisaoSituacional,
    tipo: string
  ): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.api}Dash/RecalculoVisaoSituacional/${tipo}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${this.authorization}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  DeletarTocumento(payload: any): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.post<any>(
      `${this.url}/api/Documento/Deletar`,
      {
        chaves: payload.chaves,
        tipo: payload.tipo,
        Ano: payload.Ano,
        Mes: payload.Mes,
        CNPJ_Contribuinte: payload.CNPJ_Contribuinte,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }

  BuscaEmaloteAtivo(cnpjContribuinte: string): Observable<any> {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    return this.httpClient.get<any>(
      `${this.url}/api/Dashboard/BuscaEmaloteAtivoContribuinte`,
      {
        params: {
          cnpjContribuinte: cnpjContribuinte,
        },
        headers: {
          Authorization:
            'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          usuario_compartilhado: usercompartilhado,
        },
      }
    );
  }
}
